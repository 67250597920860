<template>
    <div>
        <urbanism-regulations-form
            ref="urbanismRegulations"
            :item="item"
            :viewMode="viewMode"
            :canReviewStaff="canReviewStaff"
            :canEditStaff="canEditStaff"
        >
        </urbanism-regulations-form>

        <div class="d-flex justify-content-between mt-8">
            <st-button size="large" variant="link" :callback="cancel">
                {{ $t("APPLICATION.MODALS.CANCEL") }}
            </st-button>
            <st-button v-if="!viewMode || canEditStaff" size="large" :callback="submit">
                {{ submitButtonText }}
            </st-button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { FormSchema } from "@/shared/form/form-schema";
import { UrbanismRegulationsModel } from "@/modules/applications/models/urbanism-regulations-model";
import UrbanismRegulationsForm from '@/modules/applications/components/urbanism-regulations-edit/UrbanismRegulationsForm.vue'

const { fields } = UrbanismRegulationsModel;

const formSchema = new FormSchema([
    fields.name
])

export default {
    name: "UrbanismRegulationsDetails",
    components: {
        UrbanismRegulationsForm
    },
    props: {
        viewMode: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            required: false,
        },
        canReviewStaff: {
            type: Boolean,
            default: false,
        },
        canEditStaff: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            fields,
            model: null,
            selectedEntity: null
        };
    },
    computed: {
        ...mapGetters({
            record: "applications/form/record",
        }),
        submitButtonText() {
            return this.item ? this.$t('APPLICATION.MODALS.SAVE') : this.$t('APPLICATION.MODALS.ADD');
        },
    },
    methods: {
        async validateRlu() {
            const fieldRefs = this.$refs;
            const selectedEntityKey = Object.keys(fieldRefs).find(
                (key) => !!fieldRefs[key] && fieldRefs[key].fields
            );

            const selectedEntity = fieldRefs[selectedEntityKey];
            const status = await selectedEntity.fv.validate();
            const urbanismRegulationsDetails = { ...selectedEntity.model};

            return {
                status,
                urbanismRegulationsDetails,
            };
        },
        show() {
            this.$refs["modal"].show();
        },
        cancel() {
            this.$emit('cancelForm');
        },
        async submit() {
            const rlu = await this.validateRlu();

            if (rlu.status === 'Valid') {
                if (this.item) {
                    rlu.index = this.item.index;
                }
                this.$emit("submitForm", rlu);
            }
        },
    },
    created() {
        if (this.record?.form?.urbanism_regulations) {
            this.model = formSchema.initialValues(this.record.form.urbanism_regulations);
        } else {
            if (this.item) {
                this.model = formSchema.initialValues(this.item);
            } else {
                this.model = formSchema.initialValues({});
            }
        }
    },
    mounted() {
        this.selectedEntity = this.item ?? null;
    },
};
</script>
