<template>
    <st-modal
        id="urbanism-regulations-edit-modal"
        hide-header-delimiter
        hide-footer-delimiter
        hide-footer
        hideable
        size="xl"
        ref="modal"
        customClass="form-modal"
    >
        <template #header>
            <p class="font-size-h5 font-weight-bold mb-0 pb-0 mt-4">
                {{ $t("URBANISM_REGULATIONS_EDIT.MODAL.ADD_HEADER") }}
            </p>
        </template>
        <template #body>
            <urbanism-regulations-details
                :item="item"
                :viewMode="viewMode"
                :canReviewStaff="canReviewStaff"
                :canEditStaff="canEditStaff"
                @submitForm="submit"
                @cancelForm="cancel"
            ></urbanism-regulations-details>
        </template>
    </st-modal>
</template>

<script>
import UrbanismRegulationsDetails from '@/modules/applications/components/urbanism-regulations-edit/UrbanismRegulationsDetails.vue';

export default {
    name: "UrbanismRegulationsModal",
    props: {
        item: {
            type: Object,
            required: false,
        },
        canReviewStaff: {
            type: Boolean,
            default: false,
        },
        canEditStaff: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        UrbanismRegulationsDetails,
    },
    data() {
        return {
            viewMode: false,
        };
    },
    methods: {
        hide() {
            this.$refs["modal"].hide();
        },
        show(viewMode = false) {
            this.viewMode = viewMode;
            this.$refs["modal"].show();
        },
        submit(data) {
            this.$emit("processUrbanismRegulations", data);
            this.hide();
        },
        cancel() {
            this.$emit("cancel");
            this.hide();
        },
    },
};
</script>
